/* eslint-disable jsx-a11y/label-has-associated-control */
import { Input, Tooltip, Upload } from 'antd';
import { useChangeHandler } from 'hooks';
import PropTypes from 'prop-types';
import React, { useEffect, useState } from 'react';
// import InputMask from 'react-input-mask';
import { shallowEqual, useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import { poisCleanUp } from 'state/actions/pois';
import { regexDescription, regexInput } from 'utils/regex';
import {
  createFloor,
  fetchFloor,
  modifyFloor,
  setFloor,
} from 'state/actions/floor';
import { imgUrl } from 'utils/url';
import { log } from 'utils';
import './PoiForm.css';
import './PoiForm.scss';
import {
  createCategory,
  fetchCategories,
  modifyCategory,
  setCategory,
} from 'state/actions/categories';
import { ExportIcon } from 'assets/icon/dynamic';
import CategoryDropdown from 'components/CategoryDropdown';
import ModalCategoryForm from 'components/ModalCategoryForm';
import ModalFloorForm from 'components/ModalFloorForm';
import _ from 'underscore';
import { TrashIcon } from 'assets/icon/dynamic';

const PoiForm = ({ poi, setPoi, action, success, history, id, error }) => {
  const { siteId, siteName } = useParams();
  log(error);

  const [isDragged, setIsDragged] = useState(false);
  const [errors, setErrors] = useState(error);
  const [tempSelectedCategory, setTempSelectedCategory] = useState();
  const [modalCategory, setModalCategory] = useState(false);
  const [playlistOpen, setPlaylistOpen] = useState(false);
  const [tempSelectedFloor, setTempSelectedFloor] = useState();
  const [modalFloor, setModalFloor] = useState(false);
  const [floorOpen, setFloorOpen] = useState(false);

  useEffect(() => {
    setErrors(error);
  }, [error]);

  const { loading, userData, categoriesList, floorList, category, floor } =
    useSelector(
      (state) => ({
        loading: state.pois.loading,
        userData: state.auth.userData,
        categoriesList: state.categories.list,
        floorList: state.floor.list,
        category: state.categories.category,
        floor: state.floor.floor,
      }),
      shallowEqual
    );

  const uid = userData.id;

  const dispatch = useDispatch();

  if (poi) {
    log(poi, '<<<< POI');
  }

  useEffect(() => {
    if (success) {
      history.push(`/${siteId}/information/${siteName}`);
    }
  }, [success]); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    return () => dispatch(poisCleanUp());
  }, [dispatch]);

  useEffect(() => {
    // fetch floor and poi categories
    dispatch(fetchFloor({ siteId }));
    dispatch(fetchCategories({ siteId }));
  }, [siteId]); // eslint-disable-line react-hooks/exhaustive-deps

  const onChangeHandler = useChangeHandler(setPoi);

  const onFileChangedHandler = (_, fileList) => {
    console.log(fileList, poi.files, 'file handler');
    const files = [...poi.files, ...fileList];
    console.log(files, poi, 'file upload ');
    dispatch(
      setPoi({
        ...poi,
        files,
      })
    );
    return false;
  };
  const onQRFileChangedHandler = (qrFile) => {
    dispatch(
      setPoi({
        ...poi,
        qrFile,
      })
    );
    return false;
  };
  const onThumbnailFileChangedHandler = (thumbFile) => {
    dispatch(
      setPoi({
        ...poi,
        thumbFile,
      })
    );
    return false;
  };

  const handleDeleteQRImage = () => {
    console.log('delete image');
    dispatch(
      setPoi({
        ...poi,
        qrCode: null,
        qrFile: null,
      })
    );
  };
  const handleDeleteThumbImage = () => {
    setPoi({
      ...poi,
      thumbFile: null,
      thumbnail: null,
    });
  };

  const qrPreviewUrl = poi.qrFile
    ? poi.qrFile && URL.createObjectURL(poi.qrFile)
    : poi.qrCode && `${imgUrl}/poi/${poi.qrCode}`;

  const thumbPreviewUrl = poi.thumbFile
    ? poi.thumbFile && URL.createObjectURL(poi.thumbFile)
    : poi.thumbnail && `${imgUrl}/poi/${poi.thumbnail}`;

  const handleRemoveFile = (index) => {
    const findFiles = poi.files[index];
    const deletedFiles = [];

    if (findFiles.logo) {
      deletedFiles.push(findFiles);
    }

    const files = [...poi.files];
    files.splice(index, 1);

    console.log(files, deletedFiles, 'delte file');

    setPoi({
      ...poi,
      files,
      deletedFiles,
    });
  };

  const onSubmitHandler = (event) => {
    console.log(poi);
    event.preventDefault();
    if (
      (!regexInput(poi.name) && poi.name) ||
      (!regexInput(poi.location) && poi.location) ||
      (!regexInput(poi.eventTime) && poi.eventTime) ||
      (!regexInput(poi.ticketing) && poi.ticketing) ||
      (!regexDescription(poi.description) && poi.description) ||
      (!poi.thumbnail && !poi.thumbFile)
    ) {
      const newErrors = {
        ...errors,
        errors: {
          ...errors.errors,
        },
        message: 'Something went wrong!',
      };

      newErrors.errors.name = !regexInput(poi.name) &&
        poi.name && [
          {
            msg: 'This field cannot contain any special characters',
          },
        ];
      newErrors.errors.eventTime = !regexInput(poi.eventTime) &&
        poi.eventTime && [
          {
            msg: 'This field cannot contain any special characters',
          },
        ];
      newErrors.errors.ticketing = !regexInput(poi.ticketing) &&
        poi.ticketing && [
          {
            msg: 'This field cannot contain any special characters',
          },
        ];
      newErrors.errors.location = !regexInput(poi.location) &&
        poi.location && [
          { msg: 'This field cannot contain any special characters' },
        ];
      newErrors.errors.description = !regexDescription(poi.description) &&
        poi.description && [
          { msg: 'This field cannot contain any special characters' },
        ];
      newErrors.errors.floor = !regexInput(poi.floor) &&
        poi.floor && [
          { msg: 'This field cannot contain any special characters' },
        ];

      newErrors.errors.thumbnail = !poi.thumbnail &&
        !poi.thumbFile && [{ msg: 'This field is required' }];

      setErrors(newErrors);
    } else {
      console.log(poi, 'poi');
      dispatch(
        action({
          uid,
          ...poi,
          siteId,
          id,
        })
      );
    }
  };

  const onSelectCategoryHandler = (value) => {
    setPoi((prevState) => ({
      ...prevState,
      categoryId: tempSelectedCategory,
      categoryName: categoriesList.find(
        (cat) => cat._id === tempSelectedCategory
      )?.name,
    }));
    setPlaylistOpen(false);
  };

  const onSelectFloorHandler = () => {
    setPoi((prevState) => ({
      ...prevState,
      floorId: tempSelectedFloor,
      floorName: floorList.find((floor) => floor._id === tempSelectedFloor)
        ?.name,
    }));
    setFloorOpen(false);
  };

  const getCategory = (categoryId) => {
    const findCategory = categoriesList.find((cat) => cat._id === categoryId);
    return findCategory?.name;
  };

  const handleNew = () => {
    dispatch(
      setCategory(
        {
          _id: '',
          name: '',
          description: '',
          siteId: '',
          modifiedAt: new Date().toDateString(),
          modifiedBy: '',
        },
        () => setModalCategory(true)
      )
    );
  };

  const handleNewFloor = () => {
    dispatch(
      setFloor(
        {
          _id: '',
          name: '',
          description: '',
        },
        () => setModalFloor(true)
      )
    );
  };

  const onSubmitCategory = () => {
    const action = category._id ? modifyCategory : createCategory;

    dispatch(
      action({ ...category, siteId, id: category._id, uid }, () =>
        setModalCategory(false)
      )
    );
  };

  const handleSetCategory = (data) => {
    dispatch(setCategory(data));
  };

  const onSubmitFloor = () => {
    const action = floor._id ? modifyFloor : createFloor;

    dispatch(
      action({ uid, ...floor, siteId, id: floor._id }, () =>
        setModalFloor(false)
      )
    );
  };

  const handleSetFloor = (data) => {
    dispatch(setFloor(data));
  };

  const getFloor = (floorId) => {
    const findFloor = floorList.find((floor) => floor._id === floorId);
    return findFloor?.name;
  };

  return (
    <div className="poi-form">
      <ModalCategoryForm
        visible={modalCategory}
        onCancel={() => setModalCategory(false)}
        onCreate={onSubmitCategory}
        category={category}
        setCategory={handleSetCategory}
        isEditing={category._id ? true : false}
      />
      <ModalFloorForm
        visible={modalFloor}
        onCancel={() => {
          setModalFloor(false);
        }}
        onCreate={onSubmitFloor}
        floor={floor}
        setFloor={handleSetFloor}
        isEditing={floor._id ? true : false}
      />
      <form className="form-wrapper">
        <div>
          <div className="form">
            <label className="form-label">
              Name <span className="is-required">*</span>
            </label>
            <Input
              placeholder="POI Name"
              className={`${
                errors && errors.errors && errors.errors.name ? 'has-error' : ''
              }`}
              name="name"
              value={poi.name}
              onChange={onChangeHandler}
            />
            {errors && errors.errors && errors.errors.name && (
              <span className="error-message">{errors.errors.name[0].msg}</span>
            )}
          </div>

          <div className="form">
            <label className="form-label">
              Poi Category <span className="is-required">*</span>
            </label>
            <div
              className="playlist-wrapper"
              onClick={() => setPlaylistOpen(!playlistOpen)}
            >
              <div className="playlist-selection">
                <span>
                  {poi.categoryId === 'all'
                    ? 'Show All'
                    : getCategory(poi.categoryId)}
                </span>
              </div>
              <i
                className={`feather-chevron-down ${
                  playlistOpen ? 'playlist-open' : 'playlist-closed'
                }`}
              />

              <CategoryDropdown
                categories={categoriesList}
                setTempSelectedCategory={(id) => setTempSelectedCategory(id)}
                handleSetPlaylist={onSelectCategoryHandler}
                tempSelectedCategory={tempSelectedCategory}
                handleNew={handleNew}
                modalOpen={playlistOpen}
              />
            </div>
            {errors && errors.errors && errors.errors.categoryId && (
              <span className="error-message">
                {errors.errors.categoryId[0].msg}
              </span>
            )}
          </div>

          <div className="form">
            <label className="form-label">
              Floor <span className="is-required">*</span>
            </label>
            <div
              className="playlist-wrapper"
              onClick={() => setFloorOpen(!floorOpen)}
            >
              <div className="playlist-selection">
                <span>
                  {poi.floorId === 'all' ? 'Show All' : getFloor(poi.floorId)}
                </span>
              </div>
              <i
                className={`feather-chevron-down ${
                  playlistOpen ? 'playlist-open' : 'playlist-closed'
                }`}
              />

              <CategoryDropdown
                categories={floorList}
                setTempSelectedCategory={(id) => setTempSelectedFloor(id)}
                handleSetPlaylist={onSelectFloorHandler}
                tempSelectedCategory={tempSelectedFloor}
                handleNew={handleNewFloor}
                modalOpen={floorOpen}
                title={'Floor'}
              />
            </div>
            {errors && errors.errors && errors.errors.categoryId && (
              <span className="error-message">
                {errors.errors.floorId[0].msg}
              </span>
            )}
          </div>

          <div className="form">
            <label className="form-label">Location</label>
            <Input
              placeholder="POI Location"
              className={`${
                errors && errors.errors && errors.errors.location
                  ? 'has-error'
                  : ''
              }`}
              name="location"
              value={poi.location}
              onChange={onChangeHandler}
            />
            {errors && errors.errors && errors.errors.location && (
              <span className="error-message">
                {errors.errors.location[0].msg}
              </span>
            )}
          </div>
          <div className="form">
            <label className="form-label">Event Time</label>
            <Input.TextArea
              placeholder="Retail operating hours"
              className={`${
                errors && errors.errors && errors.errors.eventTime
                  ? 'has-error'
                  : ''
              }`}
              name="eventTime"
              value={poi.eventTime}
              onChange={onChangeHandler}
            />
            {errors && errors.errors && errors.errors.eventTime && (
              <span className="error-message">
                {errors.errors.eventTime[0].msg}
              </span>
            )}
          </div>
          <div className="form">
            <label className="form-label">Ticketing Exhibition</label>
            <Input.TextArea
              rows={2}
              placeholder="Exhibition Ticketing Exhibition"
              className={`${
                errors && errors.errors && errors.errors.ticketing
                  ? 'has-error'
                  : ''
              }`}
              name="ticketing"
              value={poi.ticketing}
              onChange={onChangeHandler}
            />
            {errors && errors.errors && errors.errors.ticketing && (
              <span className="error-message">
                {errors.errors.ticketing[0].msg}
              </span>
            )}
          </div>

          <div className="form">
            <label className="form-label">Description</label>
            <Input.TextArea
              rows={5}
              placeholder="POI Description"
              className={`${
                errors && errors.errors && errors.errors.description
                  ? 'has-error'
                  : ''
              }`}
              name="description"
              value={poi.description}
              onChange={onChangeHandler}
            />
            {errors && errors.errors && errors.errors.description && (
              <span className="error-message">
                {errors.errors.description[0].msg}
              </span>
            )}
          </div>
        </div>
        <div>
          <div className="form">
            <label className="form-label">Thumbnail</label>
            {thumbPreviewUrl ? (
              <div className="upload-image-preview">
                <Tooltip placement="top" title="Delete">
                  <button
                    type="button"
                    onClick={handleDeleteThumbImage}
                    className="btn-remove"
                  >
                    <i className="feather-x" />
                  </button>
                </Tooltip>
                <img
                  className="media-avatar"
                  src={thumbPreviewUrl}
                  alt="User profile logo preview"
                />
              </div>
            ) : (
              <Upload
                accept="image/*"
                showUploadList={false}
                beforeUpload={onThumbnailFileChangedHandler}
              >
                <div
                  onDragLeave={() => setIsDragged(false)}
                  onDragOver={() => setIsDragged(true)}
                  className={`upload-drag-and-drop ${
                    isDragged ? 'active' : ''
                  }`}
                >
                  <ExportIcon size={22} color="#C2C2C2" />
                  <p className="upload-title">
                    Drag or <span className="upload-highlight">Browse</span>
                  </p>
                  <p className="upload-subtitle">
                    File format supported: jpg, png (Up to 50MB){' '}
                  </p>
                </div>
              </Upload>
            )}
            {errors &&
              errors.errors &&
              errors.errors.thumbnail &&
              errors.errors.thumbnail.map((file, idx) => (
                <div className="error-message" key={idx}>
                  {file.msg}
                </div>
              ))}
          </div>
          <div className="form">
            <label className="form-label">QR Code</label>
            {qrPreviewUrl ? (
              <div className="upload-image-preview">
                <Tooltip placement="top" title="Delete">
                  <button
                    type="button"
                    onClick={handleDeleteQRImage}
                    className="btn-remove"
                  >
                    <i className="feather-x" />
                  </button>
                </Tooltip>
                <img
                  className="media-avatar"
                  src={qrPreviewUrl}
                  alt="User profile logo preview"
                />
              </div>
            ) : (
              <Upload
                accept="image/*"
                showUploadList={false}
                beforeUpload={onQRFileChangedHandler}
              >
                <div
                  onDragLeave={() => setIsDragged(false)}
                  onDragOver={() => setIsDragged(true)}
                  className={`upload-drag-and-drop ${
                    isDragged ? 'active' : ''
                  }`}
                >
                  <ExportIcon size={22} color="#C2C2C2" />
                  <p className="upload-title">
                    Drag or <span className="upload-highlight">Browse</span>
                  </p>
                  <p className="upload-subtitle">
                    File format supported: jpg, png (Up to 50MB){' '}
                  </p>
                </div>
              </Upload>
            )}
          </div>
          <div className="form">
            <label className="form-label">Upload Content</label>

            <Upload
              accept="image/*,video/mp4"
              showUploadList={false}
              beforeUpload={onFileChangedHandler}
              multiple
            >
              <div
                onDragLeave={() => setIsDragged(false)}
                onDragOver={() => setIsDragged(true)}
                className={`upload-drag-and-drop ${isDragged ? 'active' : ''}`}
              >
                <ExportIcon size={22} color="#C2C2C2" />
                <p className="upload-title">
                  Drag or <span className="upload-highlight">Browse</span>
                </p>
                <p className="upload-subtitle">
                  File format supported: jpg, png (Up to 50MB){' '}
                </p>
              </div>
            </Upload>
            <div className="upload-chunk-wrapper">
              {poi.files &&
                poi.files.length > 0 &&
                _.chunk(poi.files, 3).map((chunk, index) => {
                  return (
                    <div className="upload-chunk" key={index}>
                      {chunk.map((file, index2) => {
                        return (
                          <div className="upload-img-wrapper" key={index2}>
                            <button
                              type="button"
                              className="upload-remove-button"
                              onClick={() => handleRemoveFile(index2)}
                            >
                              <TrashIcon color="#CB3A31" size={18} />
                            </button>
                            <img
                              alt={file.logo}
                              src={
                                file.logo
                                  ? `${imgUrl}/poi/${file.logo}`
                                  : URL.createObjectURL(file)
                              }
                            />
                          </div>
                        );
                      })}
                    </div>
                  );
                })}
            </div>
          </div>
        </div>
      </form>
      <div
        className="button-wrapper"
        style={{ display: 'flex', justifyContent: 'flex-end' }}
      >
        <div>
          {loading ? (
            <button className="button-primary" disabled>
              Loading...
            </button>
          ) : (
            <button className="btn-primary" onClick={onSubmitHandler}>
              Submit
            </button>
          )}
          {/* <button className="button-secondary" onClick={handleReset}>
            Reset
          </button> */}
        </div>
      </div>
    </div>
  );
};

PoiForm.propTypes = {
  poiData: PropTypes.shape({
    name: PropTypes.string,
    phone: PropTypes.string,
    priority: PropTypes.string,
    description: PropTypes.string,
    logo: PropTypes.string,
    opentime: PropTypes.string,
    url: PropTypes.string,
    category: PropTypes.string,
    siteId: PropTypes.string,
    tag: PropTypes.string,
    location: PropTypes.string,
    floor: PropTypes.string,
    modifiedAt: PropTypes.string,
    modifiedBy: PropTypes.string,
  }),
  action: PropTypes.func.isRequired,
};

export default PoiForm;
